import "./contextModal.css";

const ContextModal = (props) => {
  return (
    <div className="context-modal hidden">
      <div className="context-modal-content" id="context-menu-copy">
        <p>Copy</p>
        <p className="context-modal-content_helper">Ctrl+C</p>
      </div>
      <div className="context-modal-content" id="context-menu-paste">
        <p>Paste</p>
        <p className="context-modal-content_helper">Ctrl+V</p>
      </div>
      <div className="context-modal-content" id="context-menu-remove">
        <p>Delete</p>
        <p className="context-modal-content_helper">Delete</p>
      </div>
    </div>
  );
};

export default ContextModal;
