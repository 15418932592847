import React, { Component } from "react";
import AuditItem from '../auditItem/';
import './auditBar.css';

class AuditBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            temp: 'x'
        }
    }

    // function to handle hiding audit bar
    toggleHide = () => {
        var auditList = document.getElementById("auditList");
        auditList.classList.toggle("hidden");

        var hideButton = document.getElementById("hideButton");
        if (auditList.classList.contains("hidden")) {
            hideButton.innerHTML = "&#x2771;";
        } else {
            hideButton.innerHTML = "&#x2770;";
        }

    }

    handleClick = () => {
        this.toggleHide();
    }

    sortAnnotations = () => {
        if (this.props.auditItems != null) {
            var auditItems = this.props.auditItems.map(a => JSON.parse(a));
            // Extract the create events; these define the list of all annotations
            var rootAnnotations = auditItems.filter(a => a.customData.action == 'Create');
            // Extract the non-create events; these are modifications and deletions
            var mods = auditItems.filter(a => a.customData.action != 'Create');
            // Set the .mods property for each create obj.  This is the list of all 
            // mods with the same annotation id
            var modsParsed = rootAnnotations.map(c => c.mods = mods.filter(a => a.id == c.id));
            this.rootAnnotations = rootAnnotations;
        }
    }

    componentDidMount() {
        this.sortAnnotations();
        this.setState({ temp: 2 });
    }

    render() {
        if (typeof this.rootAnnotations != 'undefined') {
            return (
                <div className="auditBar">
                    <div className="auditList" id="auditList">
                        {this.rootAnnotations.map((auditItem, index) => {
                            return (<AuditItem key={index} auditItem={auditItem} index={index + 1} isSelected={this.props.selectedItem === auditItem.id} updateSelectedItem={this.props.updateSelectedItem} />);
                        })}
                    </div>
                    <button onClick={this.handleClick} id="hideButton"> &#x2770; </button>
                </div>
            );
        } else {
            return (
                <div className="auditBar">
                    <div className="auditList" id="auditList">
                        <h3> No Annotations to Display </h3>
                    </div>
                    <button onClick={this.handleClick} id="hideButton"> &#x2770; </button>
                </div>
            )
        }
    }
}
export default AuditBar;
