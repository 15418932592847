import React, { useState } from "react";
import './sideNav.css';

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import WorkIcon from "@mui/icons-material/Work";

import TextFieldsIcon from "@mui/icons-material/TextFields";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import NoteIcon from "@mui/icons-material/Note";
import BrowserNotSupportedIcon from "@mui/icons-material/BrowserNotSupported";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BadgeIcon from "@mui/icons-material/Badge";

const SideNav = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleComponenet = () => {
    setIsOpen(!isOpen);
  }

  return (
    <div className="auditBar">
      {isOpen && <div id="sidenav" className="sidenav">
        <h3 className="sidenav-title"> FIELDS </h3>

        <hr></hr>
        <div className={`sidenav-element ${props.selectedField === "date" ? "sidenav-element--selected" : ""}`} id="calendarTodayIcon" onClick={() => props.setSelectedField("date")}>
          <CalendarTodayIcon /> <span> Date </span>
        </div>
        <div className={`sidenav-element ${props.selectedField === "date & time" ? "sidenav-element--selected" : ""}`} id="accessTimeIcon" onClick={() => props.setSelectedField("date & time")}>
          <AccessTimeIcon /> <span> Date & Time </span>
        </div>

        <hr></hr>
        <div className={`sidenav-element ${props.selectedField === "initials" ? "sidenav-element--selected" : ""}`} id="initialsIcon" onClick={() => props.setSelectedField("initials")}>
          <BadgeIcon /> <span> Initials </span>
        </div>
        <div className={`sidenav-element ${props.selectedField === "name" ? "sidenav-element--selected" : ""}`} id="nameIcon" onClick={() => props.setSelectedField("name")}>
          <PersonIcon /> <span> Name </span>
        </div>
        <div className={`sidenav-element ${props.selectedField === "firstname" ? "sidenav-element--selected" : ""}`} id="firstNameIcon" onClick={() => props.setSelectedField("firstname")}>
          <PersonOutlineIcon /> <span> FirstName </span>
        </div>
        <div className={`sidenav-element ${props.selectedField === "lastname" ? "sidenav-element--selected" : ""}`} id="lastNameIcon" onClick={() => props.setSelectedField("lastname")}>
          <PersonOutlineIcon /> <span> LastName </span>
        </div>
        <div className={`sidenav-element ${props.selectedField === "email" ? "sidenav-element--selected" : ""}`} id="emailIcon" onClick={() => props.setSelectedField("email")}>
          <EmailIcon /> <span> Email Address </span>
        </div>
        <div className={`sidenav-element ${props.selectedField === "company" ? "sidenav-element--selected" : ""}`} id="companyIcon" onClick={() => props.setSelectedField("company")}>
          <BusinessIcon /> <span> Company </span>
        </div>
        <div className={`sidenav-element ${props.selectedField === "title" ? "sidenav-element--selected" : ""}`} id="titleIcon" onClick={() => props.setSelectedField("title")}>
          <WorkIcon /> <span> Title </span>
        </div>

        <hr></hr>
        <div className={`sidenav-element ${props.selectedField === "text" ? "sidenav-element--selected" : ""}`} id="textIcon" onClick={() => props.setSelectedField("text")}>
          <TextFieldsIcon /> <span> Text </span>
        </div>
        <div className={`sidenav-element ${props.selectedField === "checkmark" ? "sidenav-element--selected" : ""}`} id="checkmarkIcon" onClick={() => props.setSelectedField("checkmark")}>
          <CheckBoxIcon /> <span> Checkmark </span>
        </div>
        <div className={`sidenav-element ${props.selectedField === "note" ? "sidenav-element--selected" : ""}`} id="noteIcon" onClick={() => props.setSelectedField("note")}>
          <NoteIcon /> <span> Note </span>
        </div>
        <div className={`sidenav-element ${props.selectedField === "n/a" ? "sidenav-element--selected" : ""}`} id="naIcon" onClick={() => props.setSelectedField("n/a")}>
          <BrowserNotSupportedIcon /> <span> N/A </span>
        </div>
      </div>}
      <button onClick={toggleComponenet} id="sidenav-hideButton"> {isOpen ? "❰" : "❱"} </button>
    </div>
  );
}

export default SideNav;
