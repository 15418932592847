
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export const WatermarkButton = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <div className="barItem relative" onClick={() => setIsOpen(true)}>
                <svg data-v-56bd7dfc="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                <p>Watermark</p>
                {isOpen ? (<Dropdown {...props} />) : null}
            </div>
        </ClickAwayListener>
    )
}

const Dropdown = (props) => {
    return (
        <div className='dropdown'>
            <FormControl sx={{ padding: 2 }}>
                <FormLabel id="horizontal-position" sx={{
                    color: "#1d1d1d",
                    fontSize: 16,
                    fontWeight: 600,
                    '&.Mui-focused': {
                        // Your custom focused styles here
                        color: 'unset', // Change to your desired focused color
                    },
                }}>Horizontal Position</FormLabel>
                <RadioGroup
                    aria-labelledby="horizontal-position"
                    name="horizontal-position"
                    value={props.horizontalPosition}
                    onChange={(e) => props.setHorizontalPosition(e.target.value)}
                >
                    <FormControlLabel value="left" control={<Radio size="16" />} label="Left" disableTypography sx={{ fontSize: 16 }} />
                    <FormControlLabel value="middle" control={<Radio size="16" />} label="Middle" disableTypography sx={{ fontSize: 16 }} />
                    <FormControlLabel value="right" control={<Radio size="16" />} label="Right" disableTypography sx={{ fontSize: 16 }} />
                </RadioGroup>
            </FormControl>
            <FormControl sx={{ padding: 2 }}>
                <FormLabel id="vertical-position" sx={{
                    color: "#1d1d1d",
                    fontSize: 16,
                    fontWeight: 600,
                    '&.Mui-focused': {
                        // Your custom focused styles here
                        color: 'unset', // Change to your desired focused color
                    },

                }}>Vertical Position</FormLabel>
                <RadioGroup
                    aria-labelledby="vertical-position"
                    name="vertical-position"
                    value={props.verticalPosition}
                    onChange={(e) => props.setVerticalPosition(e.target.value)}
                >
                    <FormControlLabel value="top" control={<Radio size="16" />} label="Top" disableTypography sx={{ fontSize: 16 }} />
                    <FormControlLabel value="middle" control={<Radio size="16" />} label="Middle" disableTypography sx={{ fontSize: 16 }} />
                    <FormControlLabel value="bottom" control={<Radio size="16" />} label="Bottom" disableTypography sx={{ fontSize: 16 }} />
                </RadioGroup>
            </FormControl>
        </div>
    )
}