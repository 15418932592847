import React, { Component } from "react";
import AuditModification from "../auditModification";
import "./auditItem.css";

class AuditItem extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { editsOpen: false };
  }

  //This is html code for down arrow; saving for ref
  ///* <span> &#9660; </span>*/
  createModsHeader() {
    return (
      <div className="mods-header">
        <span>
          <strong> Edits </strong>
        </span>
      </div>
    );
  }

  render() {
    try {
      var auditItem = this.props.auditItem;

      var index = this.props.index;

      var className = "auditItem";

      // Show modifications subset if there are edits
      if (auditItem.mods.length > 0) {
        for (var m of auditItem.mods) {
          if (m.customData.action == "Delete") {
            className += " deleted";
          }
        }

        var modsHeader = this.createModsHeader();
        var modifications = auditItem.mods.map((item, index) => {
          return <AuditModification key={index} modification={item} />;
        });
      } else {
        var editsHeader;
      }

      const annotationId = "annotation-" + auditItem.id;
      var textComponent;
      if (auditItem.customData.annotationType == "text") {
        textComponent = `Text: ${auditItem.text.value}`;
      } else {
        textComponent = "";
      }

      // check if item is selected
      if (this.props.isSelected) {
        console.log("into auditItem");
        className += " auditItem--selected";
      }
      return (
        <div
          className={className}
          id={annotationId}
          onClick={() => this.props.updateSelectedItem(auditItem.id)}
        >
          <h3>Annotation {auditItem.customData.index}</h3>
          <span>ID: {auditItem.id}</span>
          <span>Type: {auditItem.customData.annotationType}</span>
          <span>{textComponent}</span>
          <span>Created: {auditItem.customData.updatedAtCorrected}</span>
          <span>User: {auditItem.creatorName}</span>
          <button
            onClick={() => this.setState({ editsOpen: !this.state.editsOpen })}
          >
            {this.state.editsOpen ? "Close" : "Open"} Edits
          </button>
          <div
            className={`modifications ${
              this.state.editsOpen ? "" : "modifications--hidden"
            }`}
          >
            {modsHeader}
            {modifications}
          </div>
        </div>
      );
    } catch (e) {
      console.log(e);
      return "";
    }
  }
}
export default AuditItem;
