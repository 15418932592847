const prodLicense =
  "yNJwlrr2aX9qVkJ3SnTXV8z11fIxveuOP1k4iztNke73CB-k9B7G3Dz0dOVkFFpZBwedufUxS4AWLe0_KBEanG-mFCXmIE-_fQP3WCaU12aD579_luBN8fXkSZYyKxnvCPs1nDY3gyOw4DG1mlnuBly6G1ZmVKVTm9DKtpsHUFqRNXMlToYxnqCp1ZEZh8EsiSZRkurn7PuVQ3hvEmeEU07W3g-d4Wf3Th6GlLXVeLmvYuQaT4-i1JrAsypRU33wgcVH8QtXNexrM1Tzjiq23SGm9GdRnERoTbdA0keb4Ju5MQ2GHQtCrwZa-MYl4hmDLmX1o97dBKsY7ZXxJ3YG8kij58kBBZsmMSxPvkNLFT-pOUsV7rGldRdGQFYlXt0vXHlDZKtcflm3HCUpYAxW1Tr5X4iT-ATcjvSBJOeUMp6pYSwgjBSkRwCIn5Y45ySwe2qOJsG9J_u6hiD5QuqWW8BOsknS1CGcj02eNQsnlAVxnS6p-9lmC9gYtcIZR3bVyWY1hb5Bj2mDEph8XC25RWLv0bOG5q6rS3GAqubXw35ydRCy3sEtexMJ2TuUfubs7uHdpXWJdCYNaEHvaAQ1uA==";
const devLicense =
  "nO_xhbmNoTM2Seq29rNDY7rlKNAVALOrGQ9glR27ZqBKZ5KgvjFin3WI6iYrnlPOSnnPWa6i44ttFRsOJDEJGLE38zg-jlCSS_B8jhlO6pYzf4NYFHJfLxsX_oa_f91YkVz6vi0ea7UyPhNyVvh-XNZlBfrd6Mx47j2JO8JzmaDboKs7FaBqWMB4bW-ZA99d3QPk_u6rjw1WATO4QpX37_ntoIbxMuFwJ_bY6_-QPkojEDgNlJm_3Sij3-XYVVhzGowfsskoijgu7vpH8d345v4bA0K67L9zkXOFczagdL5lUXxrLg6P-YUHOesMcx5ZdEprzhxG7dH9XsTHVoaNHouIYZ_sGm52hZy7RRrErH5p3cH3W4pw5XHDAghXa9vJjKkv6bb97iUhSth9SEEiCdEXC-0OjcbOQ1_00N8gU_yZ_p84fVJlIJ3__IP5z77exH1OAGy86yVa0HyeFmDAn3z9Mqrh9Ay2nfDFX0HlbX7W_4jWcF89jHIsykNvfu7q8-buPn-ZxjNctyGLOEN___m8Jlkb4V7RMbbx7uImfQ8iCUA3uODBTwi9pwOX6eYq5b-vYIyE-bNR4D2-qfuGtKYp8Qwsee84aUKW1Nm4k7l3vdqlmNAiIemeQh-sO0ez";
export default function getLicenseKey(host, live) {
  console.log(host);

  if (host == "localhost") return "";

  if (live == "live") {
    return prodLicense;
  } else {
    return devLicense;
  }
}
